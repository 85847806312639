






































































import { Component, Vue, Watch } from "vue-property-decorator";
import AddBasic from "./components/add-basic.vue";
import AddPrice from "./components/add-price.vue";
import AddDetails from "./components/add-details.vue";
import AddSales from "./components/add-sales.vue";
import AddLogistics from "./components/add-logistics.vue";
import {
  apiGoodsAdd,
  apiGoodsDetail,
  apiGoodsEdit,
  apiGoodsOtherList,
} from "@/api/goods";
@Component({
  components: {
    AddBasic,
    AddPrice,
    AddDetails,
    AddSales,
    AddLogistics,
  },
})
export default class GoodsRelease extends Vue {
  $refs!: { form: any };
  // 商品id
  id!: any;
  loading = true;
  activeName = "basic";
  otherList = {
    supplier_list: [],
    category_list: [],
    brand_list: [],
    unit_list: [],
    freight_list: [],
	  delivery_shop_list:[],
	  self_fetch_list:[],
  };
  // 必传字段与对应的选项卡名字
  requireFields = [
    {
      fields: ["code", "name", "category_id", "goods_image"],
      activeName: "basic",
    },
    {
      fields: [
        "spec_value_list[0].sell_price",
        "spec_value_list[0].lineation_price",
        "spec_value_list[0].stock",
      ],
      activeName: "price",
    },
    {
      fields: ["express_money", "express_template_id", "delivery_content"],
      activeName: "logistics",
    },
  ];
  // 规格绑定的数据
  specData: any = {
    spec_type: 1,
    spec_value: [],
    spec_value_list: [],
    specs_single: {},
  };
  // 表单
  form: any = {
    type: 1,
    is_virtualdelivery: 1,
    after_pay: 1,
    after_delivery: 1,
    delivery_content: "",
    name: "",
    code: "",
    category_id: [],
    express_type: 1,
    express_money: "",
    express_template_id: "",
    video_source: 1,
    video_cover: "",
    video: "",
    supplier_id: "",
    brand_id: "",
    unit_id: "",
    poster: "",
    is_express: 0,
    is_selffetch: 0,
	  is_intra_city:0,
    delivery_type: 2,
    goods_image: [],
    spec_value_list: [
      {
        id: "",
        image: "",
        sell_price: "",
        lineation_price: "",
        cost_price: "",
        stock: "",
        volume: "",
        weight: "",
        bar_code: "",
      },
    ],
    stock_warning: "",
    virtual_sales_num: "",
    status: 0,
    content: "",
    is_long_range: 0,
	  self_fetch_shop_ids:[],
	  delivery_shop_ids:[],
	  is_overweight_charges:1,
	  after_sale_attr:{
		  can_refund:0,
		  can_return:0,
		  can_exchange:0,
		  is_only_refund:0,
		  can_all_refund:0,
		  is_refund:0,
	  }
  };
  // 规则验证
  rules = {
    name: [
      {
        required: true,
        message: "请输入商品名称",
        trigger: ["blur", "change"],
      },
    ],
    code: [
      {
        required: true,
        message: "请输入商品编码",
        trigger: ["blur", "change"],
      },
    ],
    category_id: [
      {
        type: "array",
        required: true,
        message: "请选择分类",
        trigger: ["blur", "change"],
      },
    ],
    platform_category_id: [
      {
        type: "array",
        required: true,
        message: "请选择分类",
        trigger: ["blur", "change"],
      },
    ],
    goods_image: [
      {
        type: "array",
        required: true,
        message: "请添加商品轮播图",
        trigger: ["blur", "change"],
      },
    ],
    video: [
      {
        required: true,
        message: "请添加视频",
        trigger: ["blur", "change"],
      },
    ],
    express_money: [
      {
        required: true,
        message: "请输入运费金额",
        trigger: ["blur", "change"],
      },
    ],
    express_template_id: [
      {
        required: true,
        message: "请选择运费模板",
        trigger: ["blur", "change"],
      },
    ],
    delivery_content: [
      {
        required: true,
        message: "请输入发货内容",
        trigger: ["blur", "change"],
      },
    ],
  };

  @Watch("form", { deep: true })
  formChange(val: any) {}
  @Watch("specData", { deep: true })
  specDataChange(val: any) {
    this.form.spec_type = val.spec_type;
    if (val.spec_type == 1) {
      this.form.spec_value_list = [val.specs_single];
      this.form.spec_value = [];
      return;
    }
    this.form.spec_value_list = val.spec_value_list;
    this.form.spec_value = val.spec_value;
  }
  // methods

  getGoodsDetail() {
    apiGoodsDetail(this.id).then((res: any) => {
      this.initData(res);
      this.form = res;
    });
  }

  onNextStep(isNext = true) {
    switch (this.activeName) {
      case "basic":
        this.activeName = "price";
        break;
      case "price":
        this.activeName = isNext ? "logistics" : "basic";
        break;
      case "logistics":
        this.activeName = isNext ? "details" : "price";
        break;
      case "details":
        this.activeName = isNext ? "sales" : "logistics";
        break;
      case "sales":
        this.activeName = "details";
        break;
    }
  }

  handleSave() {
    this.$refs.form.validate((valid: boolean, object: any) => {
      if (valid) {
        const loading = this.$loading({
          lock: true,
          text: "保存中...",
          spinner: "el-icon-loading",
        });
		  if (
			  this.form.type == 4 &&
			  this.form.is_intra_city == 1 &&
			  this.form.delivery_shop_ids.length == 0
		  ) {
			  this.$message.error('请选择配送门店')
			  loading.close()
			  this.activeName = 'logistics'
			  return false
		  }
		  if (
			  this.form.type == 4 &&
			  this.form.is_selffetch == 1 &&
			  this.form.self_fetch_shop_ids.length == 0
		  ) {
			  this.$message.error('请选择自提门店')
			  loading.close()
			  this.activeName = 'logistics'
			  return false
		  }
        const api = this.id ? apiGoodsEdit(this.form) : apiGoodsAdd(this.form);
        api
          .then(() => {
            this.$router.go(-1);
          })
          .finally(() => {
            loading.close();
          });
      } else {
        const fieldsitem = this.requireFields.find((item) => {
          for (const value of item.fields) {
            if (object[value]) {
              this.$message.error(object[value][0].message);
              return true;
            }
          }
        });
        fieldsitem && (this.activeName = fieldsitem.activeName);
        return false;
      }
    });
  }
  // 初始化规格数据
  initData(data: any = {}) {
    const spec_value = [
      {
        has_image: false,
        id: "",
        name: "",
        spec_list: [
          {
            id: "",
            value: "",
            image: "",
          },
        ],
      },
    ];
    const spec_value_list = [
      {
        id: "",
        image: "",
        sell_price: "",
        lineation_price: "",
        cost_price: "",
        stock: "",
        volume: "",
        weight: "",
        bar_code: "",
      },
    ];

    const specData: any = {
      spec_value: data.spec_value || spec_value,
      spec_value_list: data.spec_value_list || spec_value_list,
      spec_type: data.spec_type || 1,
      specs_single: spec_value_list[0],
    };
    specData.spec_value.forEach((item: any) => {
      item.has_image = false;
      item.spec_list.forEach((sitem: any) => {
        sitem.image = "";
      });
    });

    if (data.spec_type == 1) {
      specData.spec_value = spec_value;
      specData.specs_single = data.spec_value_list[0];
    }
    Object.assign(this.specData, specData);
    this.loading = false;
  }

  getGoodsOtherList() {
    apiGoodsOtherList({
      type: "all",
    }).then((res: any) => {
      this.otherList = res;
    });
  }
  created() {
    this.id = this.$route.query.id;
    this.getGoodsOtherList();
    if (this.id) {
      this.getGoodsDetail();
      return;
    }
    this.initData();
  }
}

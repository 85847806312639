



























































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AddLogistics extends Vue {
	@Prop() value: any
	@Prop({ default: () => ({}) }) lists: any
	get freightList() {
		return this.lists.freight_list || []
	}
	refreshList() {
		this.$emit('refreshFreightTemplate')
	}
	expressMoneyChange(val: any) {
		val = val.replace(/[^\d.]/g, '')
		let decimalPart = String(val).match(/\.\d*/)
		let decimalPlaces = decimalPart ? decimalPart[0].length - 1 : 0
		if (decimalPlaces > 2) {
			val = val.substring(0, val.length - (decimalPlaces - 2))
		}
		if(val=="")val=0;
		this.value.express_money = val
	}
	intraCityChange(){
		if(this.value.is_intra_city==0&&this.value.after_sale_attr.can_refund==1){
			this.value.after_sale_attr.can_refund=0;
		}
	}
	canRefundChange(val:any){
		if(val==1){
			this.value.after_sale_attr.can_return=1;
			this.value.after_sale_attr.can_exchange=1;
			this.value.after_sale_attr.is_only_refund=1;
			this.value.after_sale_attr.can_all_refund=1;
			this.value.after_sale_attr.is_refund=1;
		}
	}
	jump(path :any) {
		this.$router.push({
			path: path
		})
	}
	handleChange(val: string) {
		this.value.delivery_content = val.trim()
	}
}

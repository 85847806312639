



























import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class AddSales extends Vue {
    @Prop() value: any
}

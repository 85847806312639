var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"add-price"},[_c('el-form-item',{attrs:{"label":"商品规格","required":""}},[_c('el-radio-group',{model:{value:(_vm.value.spec_type),callback:function ($$v) {_vm.$set(_vm.value, "spec_type", $$v)},expression:"value.spec_type"}},[_c('el-radio',{attrs:{"label":1}},[_vm._v("单规格")]),_c('el-radio',{attrs:{"label":2}},[_vm._v("多规格")])],1)],1),_c('specification',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.spec_type == 2),expression:"value.spec_type == 2"}],model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.value.spec_type == 1),expression:"value.spec_type == 1"}]},[_c('el-form-item',{attrs:{"label":"成本价"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.cost_price),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "cost_price", $$v)},expression:"value.specs_single.cost_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"市场参考价","required":"","prop":"spec_value_list[0].market_reference_price","rules":[
                    {
                        required: true,
                        message: '请输入商品金额',
                        trigger: ['blur', 'change']
                    }
                ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.market_reference_price),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "market_reference_price", $$v)},expression:"value.specs_single.market_reference_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"零售价","required":"","prop":"spec_value_list[0].sell_price","rules":[
                    {
                        required: true,
                        message: '请输入商品金额',
                        trigger: ['blur', 'change']
                    }
                ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.sell_price),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "sell_price", $$v)},expression:"value.specs_single.sell_price"}},[_c('template',{slot:"append"},[_vm._v("元")])],2)],1),_c('el-form-item',{attrs:{"label":"库存","required":"","prop":"spec_value_list[0].stock","rules":[
                    {
                        required: true,
                        message: '请输入库存',
                        trigger: ['blur', 'change']
                    }
                ]}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.stock),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "stock", $$v)},expression:"value.specs_single.stock"}})],1),_c('el-form-item',{attrs:{"label":"体积"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.volume),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "volume", $$v)},expression:"value.specs_single.volume"}})],1),_c('el-form-item',{attrs:{"label":"重量","required":_vm.type == 4,"rules":_vm.type == 4
                        ? [
                              {
                                  required: true,
                                  message: '本地商品必须输入重量',
                                  trigger: ['blur', 'change']
                              }
                          ]
                        : []}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.weight),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "weight", $$v)},expression:"value.specs_single.weight"}})],1),_c('el-form-item',{attrs:{"label":"条码"}},[_c('el-input',{staticClass:"ls-input",attrs:{"type":"number"},model:{value:(_vm.value.specs_single.bar_code),callback:function ($$v) {_vm.$set(_vm.value.specs_single, "bar_code", $$v)},expression:"value.specs_single.bar_code"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }